import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { Auth0Provider } from '@auth0/auth0-react'

const config = {
  domain: process.env.REACT_APP_STAFF_ADMIN_UI_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_STAFF_ADMIN_UI_AUTH0_CLIENT_ID || '',
  audience: process.env.REACT_APP_STAFF_ADMIN_UI_AUDIENCE || '',
  scope: process.env.REACT_APP_STAFF_ADMIN_UI_SCOPE || '',
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin}
      audience={config.audience}
      scope={config.scope}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
