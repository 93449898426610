import React, { useState, useCallback, useEffect } from 'react'
import logo from './logo.svg'
import './App.css'
import { useAuth0 } from '@auth0/auth0-react'
import { LambdaVersionsTable } from './components/LambdaVersionsTable'

const audience = process.env.REACT_APP_STAFF_ADMIN_UI_AUDIENCE || ''
const scope = process.env.REACT_APP_STAFF_ADMIN_UI_SCOPE || ''

function App() {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0()

  const [token, setToken] = useState<string>('')
  const [lambdaVersions, setLambdaVersions] = useState<
    Record<string, Record<string, string>>
  >({})

  const copyToClipboard = useCallback(async () => {
    if (token) {
      await navigator.clipboard.writeText(token)
      window.alert('copied to clipboard')
    }
  }, [token])

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience,
        scope,
      })
        .then((accessToken) => {
          setToken(accessToken)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [isAuthenticated, getAccessTokenSilently])

  useEffect(() => {
    if (!isAuthenticated || !token) {
      return
    }

    fetch(`${audience}/utils/lambda-versions`, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then(async (res) => {
      const result = (await res.json()) || {}
      setLambdaVersions(result as Record<string, Record<string, string>>)
    })
  }, [isAuthenticated, token])

  return (
    <>
      <div className="text-center">
        <div>
          <img src={logo} width="100px" className="App-logo" alt="logo" />
        </div>

        {!isAuthenticated && (
          <button onClick={loginWithRedirect}>Log in</button>
        )}

        {isAuthenticated && (
          <>
            <div>Hello {user && user!.name}</div>
            <button
              onClick={() => {
                logout({ returnTo: window.location.origin })
              }}
            >
              Log out
            </button>
            {!!token && (
              <button onClick={copyToClipboard}>Copy Access Token</button>
            )}
          </>
        )}
      </div>

      {isAuthenticated && token && (
        <div>
          <h3>Lambdas Deployed Versions:</h3>
          {!!Object.keys(lambdaVersions).length ? (
            <LambdaVersionsTable lambdaVersions={lambdaVersions} />
          ) : (
            <div className="loader" />
          )}
        </div>
      )}
    </>
  )
}

export default App
