import React from 'react'

type Props = { lambdaVersions: Record<string, Record<string, string>> }

const LambdaVersionsTable: React.VFC<Props> = ({ lambdaVersions }) => {
  const lambdaNames = Object.keys(lambdaVersions).sort()
  const ENVS = ['dev-wtf', 'dev-fun', 'dev-ninja', 'stage-rocks', 'prod-io']

  return (
    <table id="lambdas-table">
      <thead>
        <tr>
          <th></th>
          {ENVS.map((env, idx) => (
            <td key={idx}>
              <b>{env}</b>
            </td>
          ))}
        </tr>
      </thead>

      <tbody>
        {lambdaNames.map((lambda, idx) => (
          <tr key={idx}>
            <td>
              <b>{lambda}</b>
            </td>
            {ENVS.map((env, idxx) => (
              <td key={idxx}>{lambdaVersions[lambda][env]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export { LambdaVersionsTable }
